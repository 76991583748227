import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSetUserApiData, useSetUserSearchParams } from 'entities/user'
import { useIsMobileModal, useSetIsMobileModal } from 'features/modal'
import classNames from 'shared/lib/class-names'

export const MainLayout = () => {
  const { isMobileModal, isMobileSmall, isSomeModalsOpened } =
    useIsMobileModal()

  useSetIsMobileModal()
  useSetUserSearchParams()
  useSetUserApiData()

  return (
    <div className="app">
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>
    </div>
  )
}
