import { useEffect, useState } from 'react'

import useAppContext from 'app/store/useAppContext'
import { someObjValuesTrue } from 'shared/utils/functions'

export const useIsMobileModal = () => {
  const {
    store: { isMobile, isMobileSmall, modals },
    dispatch,
  } = useAppContext()

  const [isMobileModal, setIsMobileModal] = useState(false)

  const isSomeModalsOpened = someObjValuesTrue(modals)

  useEffect(() => {
    setIsMobileModal(isMobileSmall && isSomeModalsOpened)
  }, [isMobileSmall, modals])

  return {
    isMobile,
    isMobileSmall,
    isMobileModal,
    isSomeModalsOpened,
    setIsMobileModal,
    modals,
    dispatch,
  }
}
