import { Question } from '../types/questions'

import Image1 from 'shared/assets/images/questions/Image1.webp'
import Image2 from 'shared/assets/images/questions/Image2.webp'
import Image3 from 'shared/assets/images/questions/Image3.webp'
import Image4 from 'shared/assets/images/questions/Image4.webp'
import Image5 from 'shared/assets/images/questions/Image5.webp'
import Image6 from 'shared/assets/images/questions/Image6.webp'

export const questions: Question[] = [
  {
    id: 1,
    text: 'Слышали ли вы о инициативе Мехриддина Абдулаева к открытию доступа к национальным достояниям для простых граждан?',
    options: ['Да, слышал', 'Ранее не слышал об этом'],
    complete: 0,
    img: Image1,
  },
  {
    id: 2,
    text: 'Сколько денег вам требуется для комфортной жизни?',
    options: [
      '500 долларов в месяц',
      '3000 долларов в месяц',
      '6000 долларов в месяц',
    ],
    complete: 20,
    img: Image2,
  },
  {
    id: 3,
    text: 'Знаете ли вы, что компания Uzbekneftegaz открыла доступ каждому гражданину Узбекистана к заработку на инвестициях в акции компании?',
    options: ['Да, знаю', 'Нет, не знал. Но хотелось бы узнать подробнее'],
    complete: 40,
    img: Image3,
  },
  {
    id: 4,
    text: 'Откуда вы узнали о новом проекте от Uzbekneftegaz?',
    options: [
      'Увидел по телевидению',
      'Прочел в газете',
      'Услышал от знакомых',
      'Увидел рекламу в соц-сетях',
    ],
    complete: 60,
    img: Image4,
  },
  {
    id: 5,
    text: 'Знали ли вы о том, что первые участники платформы приумножили свои инвестиции в 8 раз всего за месяц?',
    options: ['Да, знаю', 'Нет, не знал. Но хотелось бы узнать подробнее'],
    complete: 80,
    img: Image5,
  },
  {
    id: 6,
    text: 'На что вы потратите заработанные деньги?',
    options: [
      'Куплю дом',
      'Куплю машину',
      'Буду инвестировать, что бы заработать больше',
      'Помогу родителям',
    ],
    complete: 100,
    img: Image6,
  },
]
