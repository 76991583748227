import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { questions } from '../model/schema'
import { Questions } from './Questions/Questions'
import { ProgressBar } from 'shared/ui'

import cls from './QuestionsPage.module.scss'

export const QuestionsPage = () => {
  const { id } = useParams()
  const numberId = id ? Number(id) : 1

  const title = questions?.[numberId - 1]?.text
  const value = questions?.[numberId - 1]?.complete

  return (
    <section className={cls.page}>
      <div className={classNames('container__desktop', cls.container)}>
        <h4 className={cls.breadcrumb}>
          Как реализовать свои мечты с Uzbekneftegaz?
        </h4>

        <div className={cls.questions}>
          <h2 className={cls.questions__title}>{title}</h2>

          <ProgressBar value={value} />

          <div className={cls.questions__content}>
            <Questions page={numberId} questions={questions} />
          </div>
        </div>
      </div>
    </section>
  )
}
