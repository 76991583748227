import { Reducer } from 'react'

import type { IAppStore } from '../model/app-store'
import type { IAction } from '../model/action-types'

export const reducer: Reducer<IAppStore, IAction> = (state, action) => {
  const payload = action.payload
  const type = action.type

  switch (type) {
    case 'IS_MOBILE':
      return { ...state, isMobile: payload }
    case 'IS_MOBILE_SMALL':
      return { ...state, isMobileSmall: payload }
    case 'MODALS':
      return { ...state, modals: payload }
    case 'IS_COOKIES_MODAL':
      return { ...state, modals: { ...state.modals, isCookiesModal: payload } }
    case 'IS_ACCEPT_COOKIES_MODAL':
      return {
        ...state,
        modals: { ...state.modals, isAcceptCookiesModal: payload },
      }
    case 'IS_PRIVACY_POLICY_MODAL':
      return {
        ...state,
        modals: { ...state.modals, isPrivacyPolicyModal: payload },
      }
    case 'DOCUMENT_MODAL_TYPE':
      return {
        ...state,
        modals: { ...state.modals, documentModalType: payload },
      }
    case 'IS_AFFIRMATION_MODAL':
      return {
        ...state,
        modals: { ...state.modals, isAffirmationModal: payload },
      }
    case 'IS_SUCCESS_AFFIRMATION_MODAL':
      return {
        ...state,
        modals: { ...state.modals, isSuccessAffirmationModal: payload },
      }
    case 'USER':
      return { ...state, user: payload }
    case 'USER_API':
      return {
        ...state,
        user: { ...state.user, api: { ...state.user.api, ...payload } },
      }
    case 'USER_ACCEPTED':
      return {
        ...state,
        user: {
          ...state.user,
          accepted: { ...state.user.accepted, ...payload },
        },
      }
    case 'USER_SEARCH_PARAMS':
      return {
        ...state,
        user: {
          ...state.user,
          searchParams: { ...state.user.searchParams, ...payload },
        },
      }
    case 'USER_INFO':
      return {
        ...state,
        user: {
          ...state.user,
          info: { ...state.user.info, ...payload },
        },
      }
    case 'USER_QUESTIONS':
      return {
        ...state,
        user: {
          ...state.user,
          questions: {
            ...state.user.questions,
            ...payload,
          },
        },
      }
    default:
      return state
  }
}
