import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui/button'
import BgMobile from 'shared/assets/images/home/BgMobile.webp'

import cls from './HomePage.module.scss'

export const HomePage = () => {
  const {
    store: { isMobile, isMobileSmall },
  } = useAppContext()

  return (
    <section className={classNames('flex__col_center', cls.page)}>
      <div
        className={classNames('container__desktop_full', cls.page__container)}
      >
        <div className={cls.left_block}>
          {isMobile && <img src={BgMobile} alt="BgMobile" />}
        </div>

        <div className={cls.divider}></div>

        <div className={cls.right_block}>
          <h1 className={cls.title}>
            Как реализовать свои мечты с Uzbekneftegaz?
          </h1>
          <p className={cls.desc}>Пройдите официальный тест!</p>
          <NavLink to={`${ROUTES.QUESTIONS}/1`}>
            <Button>начать тест</Button>
          </NavLink>
        </div>
      </div>
    </section>
  )
}
