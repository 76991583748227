import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import CheckModalIcon from 'shared/assets/icons/check-modal.svg'
import { Button } from 'shared/ui/button'

import cls from './RegisteredPage.module.scss'

export const RegisteredPage = () => {
  const {
    store: {
      user: { searchParams },
    },
  } = useAppContext()

  useEffect(() => {
    if (searchParams?.metric) {
      const ReactPixel = require('react-facebook-pixel')
      ReactPixel.default.init(searchParams?.metric.toString())
      ReactPixel.default.track('Lead')
    }
  }, [])

  return (
    <div className={cls.page}>
      <div className={cls.content}>
        <img src={CheckModalIcon} alt="accept" />
        <h2 className={cls.title}>Ваша заявка принята в работу</h2>
        <p className={cls.desc}>
          Ожидайте звонка менеджера Uzbekneftegaz в течение рабочего дня
        </p>
        <a href="https://www.ung.uz/about/history" className={cls.link}>
          <Button variant="variant3">Продолжить</Button>
        </a>
      </div>
    </div>
  )
}
