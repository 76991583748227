import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setIsMobile, setIsMobileSmall } from 'app/store/actions'
import { useIsMobileScreen } from 'shared/utils/hooks'

export const useSetIsMobileModal = () => {
  const isMobile = useIsMobileScreen(768)
  const isMobileSmall = useIsMobileScreen(655)
  const { dispatch } = useAppContext()

  useEffect(() => {
    dispatch(setIsMobile(isMobile))
    dispatch(setIsMobileSmall(isMobileSmall))
  }, [isMobile, isMobileSmall])
}
