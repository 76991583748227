import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import { useIsNoAnswers } from 'features/questions'
import Image1 from 'shared/assets/images/contacts/Image1.webp'

import cls from './Contacts.module.scss'

export const ContactsPage = () => {
  const {
    store: { user },
    dispatch,
  } = useAppContext()

  useIsNoAnswers()

  return (
    <>
      <section className={cls.page}>
        <div className={classNames('container__desktop', cls.page_container)}>
          <h4 className={cls.breadcrumb}>
            Как реализовать свои мечты с Uzbekneftegaz?
          </h4>

          <div className={cls.container}>
            <h2 className={cls.title}>
              Спасибо, что прошли тест, теперь вам открыт доступ к платформе.
              Зарегистрируйтесь и начните менять свою жизнь к лучшему!
            </h2>

            <div className={cls.content}>
              <div className={cls.left_block}>
                <AffirmationForm user={user} dispatch={dispatch} />
              </div>
              <div className={cls.right_block}>
                <img src={Image1} alt="Image1" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
