import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'

import cls from './Button.module.scss'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'variant2' | 'variant3'
  className?: string
}

export function Button(props: IButton) {
  const { variant = 'default', className } = props

  return (
    <button
      {...props}
      className={classNames(cls.button, cls[variant], className)}
    />
  )
}
