import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'
import { MainLayout } from 'layouts/MainLayout'
import { ContactsPage } from 'pages/ContactsPage'
import { QuestionsPage } from 'pages/QuestionsPage'
import { HomePage } from 'pages/HomePage'
import { RegisteredPage } from 'pages/RegisteredPage'

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.MAIN} element={<MainLayout />}>
        <Route index={true} element={<HomePage />} />
        <Route path={ROUTES.CONTACTS} element={<ContactsPage />} />
        <Route path={`${ROUTES.QUESTIONS}/:id`} element={<QuestionsPage />} />
        <Route path={ROUTES.REGISTERED} element={<RegisteredPage />} />
      </Route>
    </>,
  ),
)
